'use client'

import {
  TextField,
  Box,
  BoxProps,
  InputAdornment,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import { Search as SearchIcon } from '@mui/icons-material'
import { useDebouncedValue } from 'src/core/helpers/useDebouncedValue'
import { useIsFetching } from '@tanstack/react-query'
import { eventsKeys } from 'src/core/react-query/features/events'

type SearchTermFilterProps = {
  sx?: BoxProps
  type: string
}

const SearchTermFilter: React.FC<SearchTermFilterProps> = ({ type, sx }) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathName = usePathname()
  const [searchTerm, setSearchTerm] = useState(searchParams?.get(type) || '')
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300)
  const isFetchingEvents = useIsFetching({
    queryKey: eventsKeys.allEvents._def,
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(searchParams.toString())
    queryParams.set(type, debouncedSearchTerm)

    if (debouncedSearchTerm === '') queryParams.delete(type)

    router.push(pathName + '?' + queryParams.toString())
  }, [debouncedSearchTerm])

  return (
    <Box {...sx}>
      <TextField
        fullWidth
        label='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='start'>
              {isFetchingEvents ? (
                <CircularProgress size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
export default SearchTermFilter
