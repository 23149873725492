'use client'

import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import { useGetAllEvents } from 'src/core/react-query/features/events'
import { useRouter, useSearchParams } from 'next/navigation'
import { PageUrls } from 'src/core/page-urls'
import SearchTermFilter from 'src/elements/filters/SearchTermFilter'
import { BackdropLoader } from 'src/components/BackdropLoader'
import { useModal } from 'src/components/GlobalModal'
import { CreateUpdateEventModal } from './modals/CreateUpdateEventModal'
import { Add } from '@mui/icons-material'
import { useSession } from 'next-auth/react'
import { Flex } from 'src/components/Flex'
import { EventCategoryChips } from './EventCategoryChips'
import { EventOtherChips } from './EventOtherChips'
import moment from 'moment-timezone'
import { ScrollToTopButton } from 'src/components/ScrollToTopButton'

type EventHomePageProps = {
  isAdmin?: boolean
}

export const EventsHomePage: React.FC<EventHomePageProps> = ({ isAdmin }) => {
  const theme = useTheme()
  const { showModal } = useModal()
  const router = useRouter()
  const searchParams = useSearchParams()
  const { data: session } = useSession()
  const scrollContainerElement =
    typeof window !== 'undefined' && document.getElementById('scroll-container')

  const filterParams = {
    ...(searchParams?.get('searchTerm')
      ? { term: searchParams.get('searchTerm') as string }
      : {}),
    ...(searchParams?.get('category')
      ? { categoryId: searchParams.get('category') as string }
      : {}),
    ...(searchParams?.get('subCategory')
      ? {
          subCategoryIds: searchParams.get(
            'subCategory',
          ) as unknown as string[],
        }
      : {}),
    ...(searchParams?.get('entryType')
      ? { entryTypeId: searchParams.get('entryType') as string }
      : {}),
    ...(searchParams?.get('location')
      ? { locationId: searchParams.get('location') as string }
      : {}),
    ...(searchParams?.get('startTime')
      ? {
          startTime: moment(searchParams.get('startTime'))
            .utcOffset(-moment().utcOffset())
            .toISOString() as string,
        }
      : {}),
    ...(searchParams?.get('endTime')
      ? {
          endTime: moment(searchParams.get('endTime'))
            .utcOffset(-moment().utcOffset())
            .toISOString() as string,
        }
      : {}),
  }

  const { data: events, isLoading } = useGetAllEvents(filterParams, {
    keepPreviousData: true,
  })

  if (isLoading) {
    return <BackdropLoader open />
  }

  return (
    <Box>
      <SearchTermFilter type='searchTerm' />
      <Box display='flex' flexWrap='wrap'>
        {events?.length ? (
          events?.map((event, index) => {
            return (
              <Card
                key={event.id}
                sx={{
                  mr: 2,
                  mt: 2,
                  p: 2,
                  width: 350,
                  height: 450,
                  '&:hover': {
                    cursor: 'pointer',
                    boxShadow: theme.shadows[4],
                  },
                }}
                onClick={() => {
                  isAdmin
                    ? router.push(PageUrls.adminEvent({ eventId: event.id }))
                    : router.push(PageUrls.event({ eventId: event.id }))
                }}
              >
                <Flex
                  flexDirection='column'
                  justifyContent='space-between'
                  minHeight='-webkit-fill-available'
                >
                  <Box>
                    {event?.imageUrl ? (
                      <img
                        width='500'
                        height='200'
                        style={{
                          objectFit: 'cover',
                          marginTop: '-16px',
                          marginLeft: index === 0 ? '-19px' : '-16px',
                          marginRight: '-16px',
                        }}
                        src={event?.imageUrl}
                        alt={event.title}
                      />
                    ) : (
                      <Flex
                        justifyContent='center'
                        sx={{
                          marginTop: '-16px',
                          marginLeft: index === 0 ? '-19px' : '-16px',
                          marginRight: '-16px',
                          width: 382,
                          height: 200,
                          backgroundColor: '#e8eef0',
                        }}
                      >
                        <img
                          width='75'
                          height='50'
                          style={{
                            alignSelf: 'center',
                          }}
                          src='/placeholder-image.png'
                          alt='placeholder'
                        />
                      </Flex>
                    )}
                    <Box>
                      <Typography pb={2} variant='h5'>
                        {event.title}
                      </Typography>
                      <EventOtherChips event={event} />
                    </Box>
                  </Box>

                  <EventCategoryChips event={event} />
                </Flex>
              </Card>
            )
          })
        ) : (
          <Typography pt={2}>No events found...</Typography>
        )}
      </Box>
      {session && (
        <Tooltip title='Create Event'>
          <IconButton
            sx={{
              position: 'fixed',
              width: '65px',
              height: '65px',
              bottom: 20,
              right: 20,
              zIndex: 100,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            }}
            onClick={() =>
              showModal(<CreateUpdateEventModal />, {
                onClose: () => {},
              })
            }
          >
            <Add sx={{ width: '35px', height: '35px' }} color='primary' />
          </IconButton>
        </Tooltip>
      )}
      {scrollContainerElement && (
        <ScrollToTopButton pageElement={scrollContainerElement} />
      )}
    </Box>
  )
}
